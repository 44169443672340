<template>
	<div id="ClubWorkPlan_detail" v-loading="loading" :style="{ height: height + 'px' }">
		<div class="head u-f-item u-f-jsb">
			<div>{{ title }}</div>
		</div>
		<div class="content">
			<div class="title u-f-item u-f-jsb">
				<div>
					共{{ total }}条数据
					<!-- <span class="btn" @click="selectAll">选择全部</span> -->
				</div>
				<div class="u-f-item">
					<el-button size="small" @click="add">添加设备</el-button>
					<el-button size="small" type="primary" @click="openUpdate">导入</el-button>
				</div>
			</div>
			<el-table
				:data="list"
				height="87%"
				ref="multipleTable"
				tooltip-effect="dark"
				style="width: 100%;border: 1px solid #EEEEEE;"
				key="2"
			>
				<el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
				<el-table-column label="资产名称" prop="asset_name" align="center"></el-table-column>
				<el-table-column label="股室名" prop="stock_room_name" align="center"></el-table-column>
				<el-table-column label="资产编号" prop="asset_number" align="center"></el-table-column>
				<el-table-column label="入库时间" prop="warehouse_entry_time" align="center"></el-table-column>
				<el-table-column label="规格型号" prop="specifications_model" align="center"></el-table-column>
				<el-table-column label="计量单位" prop="unit" align="center"></el-table-column>
				<el-table-column label="单价" prop="univalence" align="center"></el-table-column>
				<el-table-column label="数量" prop="num" align="center"></el-table-column>
				<el-table-column label="金额" prop="price" align="center"></el-table-column>
				<el-table-column label="凭证号" prop="voucher_number" align="center"></el-table-column>
				<el-table-column label="用途" prop="purpose" align="center"></el-table-column>
				<el-table-column label="采购方式" prop="procurement_method" align="center"></el-table-column>
				<el-table-column label="供应商" prop="supplier" align="center"></el-table-column>
				<el-table-column label="类型" prop="type" align="center"></el-table-column>
				<el-table-column label="操作" width="100" align="center">
					<template  slot-scope="scope">
						<el-button  type="text" size="small" @click="eidt(scope.row)">编辑</el-button>
						<el-button  type="text" @click="delStu(scope.row.id)" size="small">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="u-f-item u-f-right" style="padding: 15px 0;">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page.sync="page"
					:page-size="limit"
					layout="total, prev, pager, next"
					:total="total"
				></el-pagination>
			</div>
		</div>

		<!-- 添加计划 -->
		<el-dialog :title="title" :visible.sync="addShow" width="500px" :before-close="handleClose">
			<div class="dialogBody">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							股室名
						</div>
						<el-input v-model="stock_room_name" placeholder="请输入股室名" style="width: 70%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							资产编号
						</div>
						<el-input v-model="asset_number" placeholder="请输入资产编号" style="width: 70%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							资产名称
						</div>
						<el-input v-model="asset_name" placeholder="请输入资产名称" style="width: 70%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							入库时间
						</div>
						<el-date-picker value-format="yyyy-MM-dd" v-model="warehouse_entry_time" type="date" placeholder="选择日期"></el-date-picker>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							规格型号
						</div>
						<el-input v-model="specifications_model" placeholder="请输入规格型号" style="width: 70%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							计量单位
						</div>
						<el-input v-model="unit" placeholder="请输入计量单位" style="width: 70%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;margin-top: 5px;">
							<span>*</span>
							单价
						</div>
						<el-input v-model="univalence" placeholder="请输入单价" style="width: 70%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							数量
						</div>
						<el-input v-model="num" placeholder="请输入数量" style="width: 70%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							金额
						</div>
						<el-input v-model="price" placeholder="请输入金额" style="width: 70%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							凭证号
						</div>
						<el-input v-model="voucher_number" placeholder="请输入凭证号" style="width: 70%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							用途
						</div>
						<el-input v-model="purpose" placeholder="请输入用途" style="width: 70%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							采购方式
						</div>
						<el-input v-model="procurement_method" placeholder="请输入采购方式" style="width: 70%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							供应商
						</div>
						<el-input v-model="supplier" placeholder="请输入供应商" style="width: 70%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							类型
						</div>
						<el-input v-model="types" placeholder="请输入类型" style="width: 70%;"></el-input>
					</div>
				</div>
			</div>	
			
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="colseShow" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
		
		<el-dialog title="导入设备" :visible.sync="updateVisible" width="500px" :before-close="handleClose">
			<div class="update_box">
				<div class="update_item">
					<div>请按照模板格式进行录入</div>
					<div class="u-f-item" style="margin-top: 5px;">
						<!-- <img src="../../../dist/static/image/excel.png" style="width: 18px;margin-right: 3px;" /> -->
						<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
						<a :href="fileUrl">下载模板</a>
					</div>
				</div>
				<div class="u-f" style="margin-top: 20px;">
					<div style="color: #B7BDC0;margin-right: 10px;margin-top: 5px;">数据录入</div>
					<div>
						<el-button size="mini" style="position: relative;"><input ref="filElem" type="file" class="upload-file" @change="getFile">点击上传</el-button>
						<div class="u-f-item u-f-jsb file_box" v-if="file.name" style="margin-top: 10px;width: 300px;">
							<div class="u-f-item">
								<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>{{file.name}}
							</div>
							<i class="el-icon-circle-check" style="color: #67C23A;"></i>
							<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile"></i>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="gengxin">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			fileUrl:'',
			file:'',
			updateVisible:false,
			height: 500,
			loading: false,
			id:'',
			page: 1,
			limit: 14,
			total: 0,
			list: [],
			title: '',
			addShow: false,
			stock_room_name:'',
			asset_number:'',
			asset_name:'',
			warehouse_entry_time:'',
			specifications_model:'',
			unit:'',
			univalence:'',
			num:'',
			price:'',
			voucher_number:'',
			purpose:'',
			procurement_method:'',
			supplier:'',
			edit_id:'',
			type:1,
			types:'',
			title:'添加设备'
		};
	},
	created() {},
	mounted() {
		this.height = document.documentElement.clientHeight || document.body.clientHeight;
		this.id = this.$route.query.id;
		this.title = this.$route.query.title;
		this.getList();
	},
	methods: {
		closeVisible(){
			this.file = '';
			this.updateVisible = false;
		},
		getFile(e){
			console.log(this.$refs.filElem.files)
			if(this.$refs.filElem.files.length){
				this.file = this.$refs.filElem.files[0]
			}
		},
		delFile(){
			this.file = '';
			this.$refs.filElem.value = '';
		},
		gengxin(){
			let myfile = this.$refs.filElem;
			let files = myfile.files;
			let file = files[0];
			console.log(file)
			var formData = new FormData();
			formData.append("file", file);
			console.log(formData)
			this.$api.setting.importTrainingEquipment(formData).then(res=>{
				if(res.data.code==1){
					this.$message.success('导入成功');
					this.file = '';
					this.updateVisible = false;
					this.trainingBaseList();
				}else{
					this.$message.error(res.data.msg);
				}
			})
		},
		openUpdate(){
			this.file = '';
			this.$api.setting.importTempTrainingEquipment({}).then(res=>{
				if(res.data.code==1){
					this.fileUrl = res.data.data;
					this.updateVisible = true
				}
			})
		},
		add(){
			this.type = 1;
			this.addShow = true;
		},
		eidt(item){
			this.type = 2;
			this.title = '编辑设备';
			this.stock_room_name = item.stock_room_name;
			this.asset_number = item.asset_number;
			this.asset_name = item.asset_name;
			this.warehouse_entry_time = item.warehouse_entry_time;
			this.specifications_model = item.specifications_model;
			this.unit = item.unit;
			this.univalence = item.univalence;
			this.num = item.num;
			this.price = item.price;
			this.voucher_number = item.voucher_number;
			this.purpose = item.purpose;
			this.procurement_method = item.procurement_method;
			this.supplier = item.supplier;
			this.types = item.type;
			this.edit_id = item.id;
			this.addShow = true;
		},
		init(){
			this.title = '添加设备';
			this.stock_room_name = '';
			this.asset_number = '';
			this.asset_name = '';
			this.warehouse_entry_time = '';
			this.specifications_model = '';
			this.unit = '';
			this.univalence = '';
			this.num = '';
			this.price = '';
			this.voucher_number = '';
			this.purpose = '';
			this.procurement_method = '';
			this.supplier = '';
			this.types = '';
		},
		colseShow(){
			this.init()
			this.addShow = false;
		},
		// 关闭弹窗
		handleClose(done) {
			done();
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getList();
		},
		getList() {
			let data = {
				page: this.page,
				limit: this.limit,
				filter: JSON.stringify({ training_base_id: this.id })
			};
			this.$api.setting.trainingEquipmentList(data).then(res => {
				if (res.data.code == 1) {
					let list = res.data.data.rows;
					this.total = res.data.data.total;
					this.list = list;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 添加、编辑计划
		submit() {
			if (this.type == 1) {
				this.addTrainingEquipment();
			} else if (this.type == 2) {
				this.editTrainingEquipment()
			}
		},
		// 添加计划
		addTrainingEquipment() {
			this.$api.setting.addTrainingEquipment({
				stock_room_name:this.stock_room_name,
				asset_number:this.asset_number,
				asset_name:this.asset_name,
				warehouse_entry_time:this.warehouse_entry_time,
				specifications_model:this.specifications_model,
				unit:this.unit,
				univalence:this.univalence,
				num:this.num,
				price:this.price,
				voucher_number:this.voucher_number,
				purpose:this.purpose,
				procurement_method:this.procurement_method,
				supplier:this.supplier,
				type:this.types,
				training_base_id:this.id
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.getList();
					this.init()
					this.addShow = false;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 编辑计划
		editTrainingEquipment() {
			this.$api.setting.editTrainingEquipment({
				stock_room_name:this.stock_room_name,
				asset_number:this.asset_number,
				asset_name:this.asset_name,
				warehouse_entry_time:this.warehouse_entry_time,
				specifications_model:this.specifications_model,
				unit:this.unit,
				univalence:this.univalence,
				num:this.num,
				price:this.price,
				voucher_number:this.voucher_number,
				purpose:this.purpose,
				procurement_method:this.procurement_method,
				supplier:this.supplier,
				type:this.types,
				id:this.edit_id,
				training_base_id:this.id
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('修改成功');
					this.getList();
					this.init()
					this.addShow = false;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		delStu(id) {
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delTrainingEquipment({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		}
	}
};
</script>

<style lang="scss" scope>
#ClubWorkPlan_detail {
	.head {
		background-color: #4998ff;
		padding: 15px 20px;
		div {
			color: #fff;
			font-size: 16px;
		}
	}
	.content {
		background-color: #ffffff;
		margin-top: 20px;
		padding: 0 30px;
		height: calc(100% - 100px);
		overflow-y: auto;
		.title {
			padding: 0 0 15px 0;
			.btn {
				background-color: #f5f5f5;
				padding: 5px;
			}
			.btn:hover {
				color: #4998ff;
				background: #e4f1ff;
				cursor: pointer;
			}
		}
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.el-table thead {
			background: #fafafa !important;
		}
		.el-table th {
			padding: 15px 5px !important;
			background: #fafafa !important;
		}
		.el-table::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
		}
		.el-table .btn {
			padding: 5px;
			text-align: center;
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 0 35px 20px;
		.dialogBody{
			overflow-y: auto;
			height: 600px;
			.edit-box {
				.input-box {
					margin-top: 35px;
					padding-left: 15px;
					.input-lebal {
						color: #aeb5b8;
						margin-right: 10px;
						span {
							color: #ff7a65;
							margin-right: 3px;
						}
					}
					.el-icon-remove-outline {
						font-size: 1.5625vw;
						color: #d7d9db;
						margin-left: 10px;
					}
					.input-item {
						.el-select .el-input.is-disabled .el-input__inner {
							cursor: default;
						}
						.el-input.is-disabled .el-input__inner {
							background-color: #ffffff;
							cursor: default;
						}
						.el-input__prefix,
						.el-input__suffix {
							display: none;
						}
					}
				}
				.avatar-uploader .el-upload {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
				}
				.avatar-uploader .el-upload:hover {
					border-color: #409eff;
				}
				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width: 90px;
					height: 90px;
					line-height: 90px;
					text-align: center;
				}
				.avatar {
					width: 90px;
					height: 90px;
					display: block;
				}
			}
		}
		.update_box {
			padding: 40px 20px;
			.update_item {
				background-color: #f6f8fb;
				padding: 20px;
				a {
					color: #007aff;
					text-decoration: none;
				}
			}
			.upload-file{
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.file_box{
				padding: 5px;
				.el-icon-circle-check{
					display: block;
				}
				.el-icon-circle-close{
					display: none;
				}
			}
			.file_box:hover{
				color: #007AFF;
				background-color: #F6F8FB;
				.el-icon-circle-check{
					display: none;
				}
				.el-icon-circle-close{
					display: block;
				}
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
